import React from 'react'

const Banner = ({ img, text, bg = true }) => {
    return (
        <>
            {bg &&
                <div className='banner_component' style={{ background: `url(${img})` }}></div>
            }
            <div className='banner_text'><h5>{text}</h5></div>
        </>
    )
}

export default Banner