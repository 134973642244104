import { FaMapMarkerAlt } from "react-icons/fa";
import React, { useState } from 'react'
import Map, { Marker } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import GetCurrentLocation from "../utils/Location";
import { Row, Col, Image } from 'react-bootstrap';
import logo from './../assets/imgs/logo.png'

const TOKEN = 'pk.eyJ1Ijoic2tpcGlmeSIsImEiOiJjbGRsdmF4OXMwM2UxM3dudzJmZnQzNmpkIn0.GsWX-1VgRIG0sCF8yzl_4w';

const MapSection = () => {
    const [marker] = useState({
        latitude: 41.0123,
        longitude: 44.85137
    })

    const currentLocation = GetCurrentLocation();

    return (
        <div className='section'>
            {currentLocation.loaded &&

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>

                        <Map
                            initialViewState={{
                                latitude: 40.631980,
                                longitude: -74.027720,
                                zoom: 12,
                            }}
                            mapboxAccessToken={TOKEN}
                            mapStyle="mapbox://styles/mapbox/streets-v12"

                        >
                            <Marker
                                latitude={40.633430}
                                longitude={-73.967440}
                            >
                                <Image src={logo} alt="logo" width={80} />
                            </Marker>
                            <Marker
                                latitude={40.6322624}
                                longitude={-73.9670728}
                            >
                                <Image src={logo} alt="logo" width={80} />

                            </Marker>
                            <Marker
                                latitude={40.631980}
                                longitude={-74.027720}
                            >
                                <Image src={logo} alt="logo" width={80} />
                            </Marker>
                        </Map>
                    </Col>
                </Row>
            }

        </div>
    )
}

export default MapSection