
import React from "react"
import { Routes, Route } from "react-router-dom"

// IMPORTS Pages
import Home from "../pages/home"
import Menu from "../pages/menu"
import ContactUs from "../pages/contactus"
import Location from "../pages/location"
import Bolgs from "../pages/blogs"
import Blogdetail from "../pages/blogs/Blogdetail"
import CorporateResposibility from "../pages/corporate"
import GiftCard from "../pages/gift"


// Components
import Layout from "../components/Layout"


const PageRoutes = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/location" element={<Location />} />
                <Route path="/contact" element={<ContactUs />} />
                {/* <Route path="/blogs" element={<Bolgs />} /> */}
                {/* <Route path="/blog/:slug" element={<Blogdetail />} /> */}
                <Route path="/corporateresposibility" element={<CorporateResposibility />} />
                <Route path="/gift-card" element={<GiftCard />} />
            </Routes>
        </Layout>
    )
}

export default PageRoutes