import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
const About = () => {
    return (
        <div className='home_about'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="p-0">
                    <div className='back_fix about_section'>
                        <div className='back_fix_overly'>
                            <div className='back_fix_overly_text'>
                                <h4 className='section_title'>Our story / about</h4>
                                <Button variant='light'>SEE MORE</Button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6} className="p-0">
                    <div className='back_fix rewards_section'>
                        <div className='back_fix_overly'>
                            <div className='back_fix_overly_text'>
                                <h4 className='section_title'>Rewards</h4>
                                <Button variant='light'>LEARN MORE</Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default About