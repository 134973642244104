export const menus = [
    {
        title: `Chicken Platter`,
        img: require('./../assets/imgs/menu/Chicken Platter.png'),
    },
    {
        title: `Chicken Pita`,
        img: require('./../assets/imgs/menu/Chicken Sandwich.png'),
    },
    {
        title: `Falafel Platter`,
        img: require('./../assets/imgs/menu/Falafel Platter.png'),
    },
    {
        title: `Falafel Pita`,
        img: require('./../assets/imgs/menu/Falafel Sandwich.png'),
    },

    {
        title: `Lamb Platter`,
        img: require('./../assets/imgs/menu/Lamb Platter.png'),
    },
    {
        title: `Lamb Pita`,
        img: require('./../assets/imgs/menu/Lamb Sandwich.png'),
    },
    {
        title: `Mix Platter`,
        img: require('./../assets/imgs/menu/Mix Platter.png'),
    },
    {
        title: `Falafel Pocket Pita`,
        img: require('./../assets/imgs/menu/Falafel Pocket Pita.png'),
    },
    {
        title: `Chicken Pocket Pita`,
        img: require('./../assets/imgs/menu/Chicken Pocket Pita.png'),
    },
    {
        title: `Lamb Pocket Pita`,
        img: require('./../assets/imgs/menu/Lamb Pocket Pita.png'),
    },
];
