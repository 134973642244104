import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import FooterImg from './../assets/imgs/footer.png';
import { Link } from 'react-router-dom';
import { AiFillYoutube, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF, FaSearch } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer_overlay'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={10} xl={10} className='mx-auto'>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}  >
                                <Row className='d-flex justify-content-center align-items-center'>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className='d-xl-flex justify-content-center align-items-center'>
                                        <Link to="/"><Image src={FooterImg} alt='footer' className='img-fluid' /></Link>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <ul className='quick_link'>
                                            <li><Link to="#">CONTACT</Link></li>
                                            <li><Link to="#">BLOGS</Link></li>
                                            <li><Link to="#">GIFT CARDS</Link></li>
                                            <li><Link to="#">CORPORATE RESPONSIBILITY</Link></li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <ul className='quick_link'>
                                            <li><Link to="#">CONTACT</Link></li>
                                            <li><Link to="#">FRANCHISE</Link></li>
                                            <li><Link to="#">FRANCHISE DISCLAIME</Link></li>
                                            <li><Link to="#">HALAL CERTIFICATION</Link></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <ul className='quick_link_right'>
                                            <li className='text'>FOLLOW US!</li>
                                            <li><a href='https://www.facebook.com/GyroLife' target='_blank' rel="noreferrer"><FaFacebookF /></a></li>
                                            <li><a href='https://x.com/HalalGyroKing' target='_blank' rel="noreferrer"><AiOutlineTwitter /></a></li>
                                            <li><a href='https://www.instagram.com/gyro_king984/' target='_blank' rel="noreferrer"><AiOutlineInstagram /></a></li>
                                            {/* <li><a href='#' target='_blank'><AiFillYoutube /></a></li> */}
                                            <li><a href='https://www.tiktok.com/@thegyroking?_t=8pm1ENGy4ic&_r=1' target='_blank' rel="noreferrer"><SiTiktok /></a></li>

                                            {/* <li><a href='#'><FaFacebookF /></a></li>
                                            <li><a href='#'><AiOutlineTwitter /></a></li>
                                            <li><a href='#'><AiOutlineInstagram /></a></li>
                                            <li><a href='#'><AiFillYoutube /></a></li>
                                            <li><a href='#'><SiTiktok /></a></li> */}
                                        </ul>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <ul className='quick_link_right'>
                                            <li className='text text-white'>DOWNLOAD OUR APP!</li>
                                            <li><a href='#'><Image src={require('./../assets/imgs/apple.png')} alt='apple' /></a></li>
                                            <li><a href='#'><Image src={require('./../assets/imgs/goolge.png')} alt='google' /></a></li>
                                        </ul>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <ul className='quick_link_right'>
                                            <li className='text'>ORDER ONLINE NOW</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer