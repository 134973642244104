import React, { useEffect } from 'react'
// Imports
import Banner from '../../components/Banner'
import BannerLocation from './../../assets/imgs/bannerlocation.png'
import MapSection from '../../sections/MapSection'


const Location = () => {
    useEffect(() => {
        document.title = 'Gyroking | Closest Resturent';
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Banner img={BannerLocation} text="LOCATIONS" />
            <MapSection />
        </>
    )
}

export default Location