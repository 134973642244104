import React from 'react'
import { Button, } from 'react-bootstrap'
const Catering = () => {
    return (
        <div className='catering_section'>
            <video autoPlay loop muted playsInline>
                <source src={require('./../assets/imgs/CateringVid.webm')} type="video/webm" />
                <source src={require('./../assets/imgs/CateringVid.webm')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className='catering_section_overlay'>
                <h4 className='section_title2' >Catering</h4>
                <p className='des mb-5 text-center' >Full Service Catering Available</p>
                <Button variant='success'>ORDER NOW</Button>
            </div>
        </div>
    )
}

export default Catering