import React, { useState } from 'react'
import { FaPlus } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { Navbar, Nav, Image } from 'react-bootstrap'
import Logo from './../assets/imgs/logo.png';
import { Link } from "react-router-dom";

const Header = () => {
    const [expanded, setExpanded] = useState(false)
    return (
        <div className='header'>
            <Navbar expanded={expanded} expand="lg" className="bg-body-tertiary">
                <Link className="navbar-brand" to="/">
                    <Image src={Logo} alt='logo' width={220} />
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="responsive-navbar-nav" className='d-xl-flex justify-content-end'>
                    <Nav>
                        <li onClick={() => setExpanded(false)}>
                            <Link className="nav-link" to="/location">
                                <span>Closest Restaurant</span>
                                <MdLocationOn />
                            </Link>
                        </li>
                        <li onClick={() => setExpanded(false)}>
                            <Link className="nav-link" to="">
                                <span>Cater Now</span>
                                <FaPlus />
                            </Link>
                        </li>
                        <li onClick={() => setExpanded(false)}>
                            <Link className="nav-link" to="">
                                <span>Order Now</span>
                                <FaPlus />
                            </Link>
                        </li>
                        <li onClick={() => setExpanded(false)}>
                            <Link className="nav-link" to="">
                                <FiMenu />
                            </Link>
                        </li>



                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header