import { MdLocationOn } from "react-icons/md";
import React from 'react'
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const HomeHeroSection = () => {
    return (
        <div className='home_hero_section'>

            <video autoPlay loop muted playsInline>
                <source src={require('./../assets/imgs/hero.webm')} type="video/webm" />
                <source src={require('./../assets/imgs/hero.webm')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className='home_hero_section_overlay' >
                <div className='franchise' data-aos="slide-left" data-aos-duration="1000">
                    <h4>Now</h4>
                    <p>Franchise option</p>
                    <h5>Available</h5>
                    <Link to='/contact' className='w-100 small btn-dark btn'>Apply For Franchise <BiRightArrowAlt /></Link>
                </div>

                <Link to='/location' className="location" data-aos="zoom-in" data-aos-duration="1000" >
                    <div className="marker">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                        <div className="waves wave-4"></div>
                        <div className="waves wave-5"></div>
                        <MdLocationOn />
                    </div>
                    <div className="location_text">
                        <h4>Closest Restaurant</h4>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default HomeHeroSection