import React, { useEffect } from 'react'
import contactImg from './../../assets/imgs/contactus.png'
import Banner from '../../components/Banner'
import ContactForm from '../../sections/ContactForm'

const ContactUs = () => {
    useEffect(() => {
        document.title = 'Gyroking | Contact Us'
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Banner img={contactImg} text="CONTACT US" />
            <ContactForm />

        </>
    )
}

export default ContactUs