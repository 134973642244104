import React from 'react'

const LocationOutLine = () => {
    return (
        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.09375 25.1689H19.1066" stroke="#2F1B70" stroke-width="1.44352" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.0325 13.8266C11.9117 13.8266 13.435 12.3033 13.435 10.4241C13.435 8.54487 11.9117 7.02148 10.0325 7.02148C8.15327 7.02148 6.62988 8.54487 6.62988 10.4241C6.62988 12.3033 8.15327 13.8266 10.0325 13.8266Z" stroke="black" stroke-width="1.44352" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.1061 10.5114C19.1061 18.7561 10.0325 25.1686 10.0325 25.1686C10.0325 25.1686 0.958984 18.7561 0.958984 10.5114C0.958984 8.08178 1.91494 5.7517 3.61656 4.03372C5.31818 2.31574 7.62607 1.35059 10.0325 1.35059C12.439 1.35059 14.7469 2.31574 16.4485 4.03372C18.1501 5.7517 19.1061 8.08178 19.1061 10.5114V10.5114Z" stroke="black" stroke-width="1.44352" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default LocationOutLine