import React from 'react'

const Phone = () => {
    return (
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1296 2.34452C18.8893 2.81679 20.4939 3.74361 21.7822 5.03195C23.0705 6.32029 23.9974 7.92483 24.4696 9.68455M16.0532 6.35171C17.1116 6.63279 18.0768 7.18866 18.8512 7.96298C19.6255 8.7373 20.1814 9.70255 20.4624 10.7609M8.45384 13.3416C9.52141 15.5462 11.3041 17.3243 13.5115 18.3862C13.6743 18.4633 13.8544 18.4967 14.034 18.4831C14.2136 18.4694 14.3866 18.4092 14.5359 18.3084L17.778 16.1427C17.9212 16.0456 18.0868 15.9863 18.2591 15.9704C18.4315 15.9546 18.605 15.9826 18.7636 16.052L24.8327 18.6586C25.0402 18.7449 25.2135 18.897 25.3259 19.0915C25.4384 19.286 25.4838 19.5121 25.4552 19.7349C25.2628 21.2363 24.53 22.6162 23.3938 23.6163C22.2576 24.6164 20.796 25.1683 19.2823 25.1686C14.6047 25.1686 10.1188 23.3105 6.81121 20.0029C3.50367 16.6954 1.64551 12.2094 1.64551 7.53182C1.64585 6.01816 2.19771 4.55649 3.19784 3.42031C4.19797 2.28413 5.57782 1.5513 7.0792 1.35894C7.30205 1.33031 7.52812 1.37574 7.72262 1.48821C7.91711 1.60069 8.06923 1.77399 8.15557 1.98141L10.7622 8.06352C10.83 8.21959 10.8583 8.38994 10.8448 8.55955C10.8312 8.72916 10.7761 8.89283 10.6844 9.03614L8.51868 12.3301C8.42226 12.4791 8.36567 12.6503 8.35432 12.8274C8.34297 13.0045 8.37723 13.1815 8.45384 13.3416Z" stroke="black" stroke-width="1.44352" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Phone