
import React from 'react'
import { AiFillYoutube, AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF, FaSearch } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import { BsTelephoneFill } from "react-icons/bs";
import { Image, Form } from 'react-bootstrap';

const TopHeader = () => {
    return (
        <div className='top_header'>
            <ul className='social_list'>
                <li><a href='https://www.facebook.com/GyroLife' target='_blank' rel="noreferrer"><FaFacebookF /></a></li>
                <li><a href='https://x.com/HalalGyroKing' target='_blank' rel="noreferrer"><AiOutlineTwitter /></a></li>
                <li><a href='https://www.instagram.com/gyro_king984/' target='_blank' rel="noreferrer"><AiOutlineInstagram /></a></li>
                {/* <li><a href='#' target='_blank'><AiFillYoutube /></a></li> */}
                <li><a href='https://www.tiktok.com/@thegyroking?_t=8pm1ENGy4ic&_r=1' target='_blank' rel="noreferrer"><SiTiktok /></a></li>

                <li className='phone'>
                    <BsTelephoneFill />
                    <span></span>
                    <p><a href='tel:+19177735640' style={{ fontSize: "0.8rem", padding: "0" }}>(+1) 917-773-5640</a></p>
                </li>
            </ul>
            {/* <ul className='search_ul'>
                <li>
                    <FaSearch />
                    <Form.Control type="email" placeholder="Search..." />
                </li>
                <li>
                    <div className='drop'>
                        <div className='icon'>
                            <Image src={require('./../assets/imgs/uk.jpg')} />
                        </div>
                        <div className='iconText'>
                            <span>Language</span>
                            <p>English</p>
                        </div>
                    </div>
                </li>
            </ul> */}
        </div>
    )
}

export default TopHeader