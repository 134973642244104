import React from 'react'

const At = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0371 13.2568C18.0371 15.9973 15.8155 18.2189 13.075 18.2189C10.3346 18.2189 8.11296 15.9973 8.11296 13.2568C8.11296 10.5163 10.3346 8.29472 13.075 8.29472C15.8155 8.29472 18.0371 10.5163 18.0371 13.2568ZM18.0371 13.2568C18.0371 15.9984 19.0296 18.2189 21.5106 18.2189C23.9916 18.2189 24.9841 15.9984 24.9841 13.2568C24.9834 10.6035 24.0967 8.02634 22.4647 5.93432C20.8326 3.84231 18.5486 2.35525 15.9751 1.70911C13.4017 1.06296 10.6862 1.29474 8.25942 2.36767C5.83269 3.44059 3.83377 5.29321 2.57983 7.63155C1.32589 9.96989 0.888772 12.66 1.33782 15.2751C1.78687 17.8901 3.09637 20.2803 5.05853 22.0664C7.0207 23.8525 9.52314 24.9322 12.1688 25.1341C14.8144 25.336 17.4517 24.6486 19.6622 23.181M18.0371 13.2568V8.29472" stroke="black" stroke-width="1.44352" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default At