import React, { useEffect } from 'react'
import Banner from '../../components/Banner'
import { Button, Col, Row, } from 'react-bootstrap'
import Cards from './../../assets/imgs/gift.png'

const GiftCard = () => {
    useEffect(() => {
        document.title = 'Gyroking | Gift Card';
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='corporate'>
            <Banner text="GIFT CARDS" img={Cards} />
            <div className='section'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={9} className='mx-auto '>
                        <div className='blog_detail_text'>
                            <h2 className='mb-5'>Give the Gift of Great Food!</h2>
                            <p>A gift that’s just the right size and perfect for all occasions: birthdays, holidays, graduations, and celebrations of all types.</p>

                            <p>The Gyro King Gift Cards can be purchased at any of our participating US locations and online (our gift cards and e-gift cards are not redeemable in any of our international locations at this time). The Gyro King Gift Cards can be purchased at any of our participating US locations and online (our gift cards and e-gift cards are not redeemable in any of our international locations at this time). </p>
                            <p>Give as a gift or treat yourself!</p>
                            <Row>
                                <Col xs={10} sm={10} md={6} lg={4} xl={4} className='mt-3'>
                                    <Button variant='success'>BUY NOW</Button>
                                </Col>
                            </Row>
                        </div>


                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default GiftCard