import React, { useState } from 'react'
import { Card, Col, Row, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { At, LocationOutLine, Phone } from '../assets/icons'
import { useFormik } from 'formik'
import { basicSchema } from '../schemas'
import axios from 'axios'
import toast from 'react-hot-toast'
const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const onSubmit = async (values, actions) => {
        try {
            await toast.promise(
                axios.post('', values),
                {
                    loading: 'Request Is Submitting...',
                    success: 'Request submitted Successful!',
                    error: 'Request not submitted',
                }
            ).then((res) => {
                if (res && res?.data) {
                    actions?.resetForm()
                }
            });

        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    }
    const { values, errors, touched, handleChange, handleSubmit } = useFormik({
        initialValues: { name: '', email: '', phone: '', subject: '', content: '' },
        validationSchema: basicSchema,
        onSubmit,
    })
    return (
        <div className='section contactus'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={10} xl={10} className='mx-auto'>
                    <h4>We would love to hear from you!</h4>
                    <p>The Gyro King are available to answer any questions that you may have. Please contact us for catering, private parties, or any general inquiries and a member of our team will get back to you within 48 hours.
                        We receive many questions. Find answers to some of the most common ones in our  <Link to="/faqs">FAQs</Link>.
                    </p>
                    <p>Feel free to contact us for questions or general information: </p>
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={12} md={12} lg={10} xl={9} className='mx-auto mt-5'>
                    <Row className='d-flex justify-content-center '>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-3" >
                            <Card className='contactCard'>
                                <Card.Body>
                                    <div className='contacticon'>
                                        <LocationOutLine />
                                    </div>
                                    <h4>Address</h4>
                                    <p><a href='https://www.google.com/maps/place/984+Coney+Island+Ave,+Brooklyn,+NY+11230/@40.633419,-73.9700147,17z/data=!3m1!4b1!4m6!3m5!1s0x89c244ccc505e711:0xb622148126bd8544!8m2!3d40.633415!4d-73.9674398!16s%2Fg%2F11bw4cm4bs?entry=tts&g_ep=EgoyMDI0MDkxMS4wKgBIAVAD' target='_black'>984 Coney Island Ave, Brooklyn, <br /> NY 11230</a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-3">
                            <Card className='contactCard'>
                                <Card.Body>
                                    <div className='contacticon'>
                                        <Phone />
                                    </div>
                                    <h4>Call Us</h4>
                                    {/* <p><a href='tel:+19177735640'>(+1) 917-773-5640</a></p> */}
                                    <p><a href='tel:+19177735640'>(+1) 917-773-5640</a></p>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-3">
                            <Card className='contactCard'>
                                <Card.Body>
                                    <div className='contacticon'>
                                        <At />
                                    </div>
                                    <h4>E-Mail Us </h4>
                                    <p><a href='mailto:gyroking984@gmail.com'>gyroking984@gmail.com</a></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <div className='formsection'>
                        <Form onSubmit={handleSubmit} method='POST'>

                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                        <Form.Label>Name *</Form.Label>
                                        <Form.Control type="text" placeholder="Jhon Doe" id="name" value={values?.name} onChange={handleChange} />
                                        {errors?.name || touched.name ? <span className='text-danger'>{errors?.name}</span> : ''}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control type="email" placeholder="info@example.com" id="email" value={values?.email} onChange={handleChange} />
                                        {errors?.email || touched.email ? <span className='text-danger'>{errors?.email}</span> : ''}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Phone *</Form.Label>
                                        <Form.Control type="text" id="phone" placeholder='(+1) 917-773-5640' value={values?.phone} onChange={handleChange} />
                                        {errors?.phone || touched.phone ? <span className='text-danger'>{errors?.phone}</span> : ''}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Label>Subject *</Form.Label>
                                        <Form.Control type="text" id="subject" value={values?.subject} onChange={handleChange} />
                                        {errors?.subject || touched.subject ? <span className='text-danger'>{errors?.subject}</span> : ''}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                        <Form.Label>We can help you?</Form.Label>
                                        <Form.Control as="textarea" rows={10} id="content" value={values?.content} onChange={handleChange} />
                                    </Form.Group>
                                </Col>

                                <Col xs={6} sm={6} md={4} lg={4} xl={3} className='mx-auto'>
                                    <Button variant='dark' className='w-100' type='submit'>Send Message</Button>
                                </Col>

                            </Row>
                        </Form>

                    </div>
                </Col>
            </Row>


        </div>
    )
}

export default ContactForm