import React, { useEffect } from 'react'
import HomeHeroSection from '../../sections/HomeHeroSection'
import Menu from '../../sections/Menu'
import About from '../../sections/About'
import HistoryBanner from '../../sections/HistoryBanner'
import Catring from '../../sections/Catring'

const Home = () => {
    useEffect(() => {
        document.title = 'Gyroking | Home';
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <HomeHeroSection />
            <Menu />
            <About />
            <HistoryBanner />
            <Catring />

        </>
    )
}

export default Home
