import React from 'react'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { menus } from '../utils/data';

const Menu = () => {



    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 9,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
            // You can add more breakpoints if needed
        ]
    };

    return (
        <div className='menu_section'>
            <h4 className='section_title text-center mb-xl-5'>Menu</h4>
            <div className='menu_slider'>
                <Slider {...settings}>
                    {menus?.map((slide, s) => (
                        <div key={s}>
                            <Link to="/menu" className='slide_item' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay={s * 500}>
                                <div className='slide_item_image'>
                                    <Image src={slide?.img} alt='menu' className='img-fluid' />
                                </div>
                                <p>{slide?.title}</p>
                            </Link>
                        </div>
                    ))}

                </Slider>
            </div>
        </div>
    )
}

export default Menu