import React, { useEffect } from 'react'

// Imports
import Banner from '../../components/Banner'
import MenuList from '../../sections/MenuList'
import BannerImg from './../../assets/imgs/bannermenu2.png'

const Menu = () => {
    useEffect(() => {
        document.title = 'Gyroking | Menu';
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Banner img={BannerImg} text="Menu" />
            <MenuList />
        </>
    )
}

export default Menu