
import React, { useEffect } from 'react'
import Banner from '../../components/Banner'
import { Col, Row, } from 'react-bootstrap'
import Corp from './../../assets/imgs/cor.png'
const CorporateResposibility = () => {
    useEffect(() => {
        document.title = 'Gyroking | Corporate Responsibility'
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='corporate'>
            <Banner text="CORPORATE RESPONSIBILITY" img={Corp} />
            <div className='section'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={10} className='mx-auto '>
                        <div className='blog_detail_text'>
                            <h2>The Gyro King is committed to growing our business in an ethical and responsible manner through mutual trust and respect with our suppliers, employees, and customers.</h2>

                            <h4 className='mt-5'>Protecting The Environment</h4>
                            <p>The quality of these relationships drives the success of our company. We are very cognizant that our activities affect the society in which we do business.</p>
                            <p>Because the essence of our concept is about supporting the communities in which we serve, The Gyro King is committed to serving high-quality, fresh, and flavorful ingredients. We strive, to the best of our ability, to develop food industry best practices that support purveyors of Halal-certified food.</p>
                            <p>As such, The Gyro King strives to maintain the highest standards of ethics and integrity in its operations, respecting the rule of law, human dignity, and the rights of the individual. Moreover, we aspire to safe and healthy working environments, while focusing on reducing the environmental impact of our operations.</p>
                            <h4 className='mt-5'>Protecting People</h4>
                            <p>As to protecting the environment, The Gyro King recognizes the need to protect the natural environment. Keeping our environment clean and unpolluted is a benefit to all. We’ll always follow best practices when disposing of garbage, sustainable energy sources, water efficiency, composting, waste reduction and recycling, and overall preservation and protection of the environment.</p>

                            <h4 className='mt-5'>Protecting People</h4>
                            <h4 className='my-3'>The Gyro King will ensure we: </h4>
                            <ul>
                                <li>Don’t risk the health and safety of our employees and community.</li>
                                <li>Avoid harming the lives of local and indigenous people.</li>
                                <li>Support diversity and inclusion.</li>
                            </ul>
                            <h4 className='mt-5'>Human rights</h4>
                            <p>
                                The Gyro King is dedicated to protecting human rights. We are a committed equal opportunity employer and will abide by all fair labor practices. We’ll ensure that our activities do not directly or indirectly violate human rights in any country (e.g., forced labor).</p>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default CorporateResposibility