import React, { useState } from 'react'
import { Row, Col, Card, Image, Button } from 'react-bootstrap'
import { menus } from '../utils/data';


const MenuList = () => {
    const [active, setActive] = useState('ALL');
    const tabs = ['ALL', 'Platter', 'Sandwich', 'side', 'Deserts'];
    const [filter, setFilter] = useState(menus);


    const handleFilter = (m) => {
        setActive(m)
        if (m == 'ALL') {
            setFilter(menus)
        } else {
            const fltr = menus.filter(item => item?.title?.includes(m));
            setFilter(fltr)
        }
    }

    return (
        <div className='section bg-gray'>
            <Row>
                <Col xs={12} sm={12} md={10} lg={10} xl={10} xxl={8} className='mx-auto'>
                    <ul className='mb-5 nav nav-tabs nav-fill'>
                        {tabs.map((men, m) => (
                            <li className='nav-item' key={m}><button className={`nav-link ${active == men ? 'active' : ''}`} onClick={() => handleFilter(men)}>{men}</button></li>
                        ))}
                    </ul>

                    <Row className='d-flex justify-content-center'>
                        {filter?.map((menu, u) => (
                            <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4} key={u} className='mb-4'>
                                <Card className='menuCard'>
                                    <Card.Body>
                                        <Image src={menu?.img} alt="menu" className='img-fluid' />
                                        <Card.Title>{menu?.title}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Row className='d-flex justify-content-center'>
                        <Col xs={10} sm={10} md={6} lg={4} xl={4} className='mt-3 mx-auto text-center'>
                            <Button variant='success'>LOAD MORE</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default MenuList