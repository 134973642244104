
import Aos from "aos";
import PageRoutes from "./routes";
function App() {

  Aos.init({
    duration: 1500,
  });

  return (
    <PageRoutes />
  );
}

export default App;
