import React from 'react'

const HistoryBanner = () => {
    return (
        <div className='history_banner'>
            <p>Since 2000,  Chicken, Gyro, and Falaffel Platters. The Authentic Zabiha Halal Food.</p>
        </div>
    )
}

export default HistoryBanner